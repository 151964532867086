@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

@layer base {
  * {
    @apply focus-visible:outline outline-1 focus-visible:outline-2 outline-offset-2 focus-visible:outline-primary-7;
  }

  [type="text"]:focus,
  input:where(:not([type])),
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    @apply outline outline-2 outline-offset-0 outline-primary-a-7 border-transparent ring-0 ring-offset-0;
  }
}

:root {
  --sphere-base-size: 24vw;
}
.sphere {
  border-radius: 50%;
  width: calc(var(--sphere-base-size) * 1);
  height: calc(var(--sphere-base-size) * 1);
  box-shadow:
    0px 15px 20px rgba(0, 0, 0, 0.4),
    inset 0px 0px 15px rgba(255, 255, 255, 0.4),
    inset 0px 10px 30px rgba(0, 0, 0, 0.5);
  z-index: -10;
}

.sphere1 {
  --sphere-base-size: 10vw;
}

.sphere2 {
  --sphere-base-size: 12vw;
}

.sphere3 {
  --sphere-base-size: 15vw;
}

.grid-background {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  height: 100%;
  width: 100%;
  opacity: 0.4;
  transform: perspective(50vh) rotateX(60deg) translateZ(10px);
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -20;
}

.dark .grid-background {
  background-image: linear-gradient(to right, rgba(235, 224, 224, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(235, 224, 224, 0.1) 1px, transparent 1px);
}
